import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// import Loading from './Loading';
import { AuthContext, AuthType } from '../mir-styles/src/providers/AuthProvider';


// TODO move this to a context provider
export const RequiredAuth: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { checkToken } = useContext(AuthContext) as AuthType;

  if (!checkToken()) return <Navigate to='/login' replace />

  return <Outlet />
}