import React, { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useOktaAuth } from '@okta/okta-react';
import { LoginCallback } from '@okta/okta-react';
import { UserClaims } from '@okta/okta-auth-js';

// mir-styles
import MirAppBar from '../mir-styles/src/components/AppBar/MirAppBar';
import { MirAppBarContext, miRAppBarType } from '../mir-styles/src/providers/MirAppBarProvider';

import Loading from './Loading';
import { RequiredAuth } from './SecureRoute';
import Login from '../pages/Login/Login';
import AppSelector from '../pages/AppSelector';
import { AuthContext, AuthType } from '../mir-styles/src/providers/AuthProvider';
import LoginSSO from '../mir-styles/src/components/LoginSSO/LoginSSO';

const AppRoutes = () => {
  const { mirAppBarState, updateMirAppbar } = useContext(MirAppBarContext) as miRAppBarType;
  const { checkToken } = useContext(AuthContext) as AuthType;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['mir_auth']);
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    let userInfo: UserClaims | undefined = undefined;

    const fetchUserInfo = async () => {
      if (checkToken()) {
        userInfo = await oktaAuth.getUser();
      }
      updateMirAppbar({
        logout: _handleLogout,
        user: userInfo,
        title: !!userInfo ? 'Apps' : undefined
      })
    };
    fetchUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  const _handleLogout = () => {
    removeCookie('mir_auth', { domain: process.env.REACT_APP_COOKIES_DOMAIN })
    oktaAuth.signOut({ postLogoutRedirectUri: process.env.REACT_APP_MIR_LOGIN_APP })
  }

  return (
    <>
      <MirAppBar title={mirAppBarState.title} logout={mirAppBarState.logout} user={mirAppBarState.user} />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/login/sso' element={<LoginSSO />} />
        <Route path='/login/callback' element={<LoginCallback loadingElement={<Loading />} />} />
        <Route path='/apps' element={<RequiredAuth />}>
          <Route path='' element={<AppSelector />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;