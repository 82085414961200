import React from 'react';

import { Grid, Button } from '@mui/material';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface AppButtonProps {
  title: string,
  image: string,
  onClick: () => void;
}

const AppButton: React.FC<AppButtonProps> = ({ title, onClick, image }) => {
  return (
    <Grid container
      style={{ width: '100%' }}
      justifyContent="center"
      alignItems="center"
    >

      <Grid item xs={12}>
        <img src={image} style={{ width: '240px', height: '240px' }} className="App-logo" alt="logo" />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="outlined"
          size='large'
          onClick={onClick}
          endIcon={<ArrowForwardIcon />}
          sx={{
            borderRadius: '50rem',
            borderWidth: '3px',
            borderStyle: 'solid',
            '&:hover': {
              borderWidth: '3px',
              backgroundColor: '#408cff2f'
            },
          }}
        >
          {title}
        </Button>
      </Grid>

    </Grid>
  );
};

export default AppButton;