import shipping_logo from './../shipping_logo.svg';
import classifier_logo from './../classifier_logo.svg';

const MirApps: MirApp[] = [
    {
        group: 'mir_shipping_app',
        title: 'Shipping',
        url: !!process.env.REACT_APP_SHIPPING_APP ? process.env.REACT_APP_SHIPPING_APP : '',
        logo: shipping_logo
    },
    {
        group: 'mir_classifier_app',
        title: 'Classifier',
        url: !!process.env.REACT_APP_CLASSIFIER_APP ? process.env.REACT_APP_CLASSIFIER_APP : '',
        logo: classifier_logo
    }
]

export interface MirApp {
  group?: string;
  title: string;
  url: string;
  logo?: string;
}

export default MirApps;