import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const LoginSSO = () => {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    async function signIn() {
      if (!await oktaAuth.isAuthenticated()) {
        await oktaAuth.signInWithRedirect();
      } else {
        navigate('/')
      }
    }
    signIn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>You logged in using okta dashboard</>
  );
};

export default LoginSSO;
