import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import AppButton from '../components/AppButton';
import default_logo from './../logo.jpeg'
import { AlertMsgContext, AlertMsgType } from '../mir-styles/src/providers/AlertMsgProvider';
import MirApps, { MirApp } from '../models/MirApp';
import { AuthContext, AuthType } from '../mir-styles/src/providers/AuthProvider';

const AppSelector = () => {
  const { updateAlertMsg } = useContext(AlertMsgContext) as AlertMsgType;
  const { getGroups } = useContext(AuthContext) as AuthType;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['mir_auth']);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [authorizedApps, setAuthorizedApps] = useState<MirApp[]>([])

  useEffect(() => {
    const errorParam = searchParams.get("error")
    if (!!errorParam && errorParam === 'NOT_AUTHORIZED') {
      updateAlertMsg({
        severity: 'error',
        message: 'You are not authorized to use that application',
        open: true
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    const groups = getGroups()
    const newApps: MirApp[] = []
    MirApps.forEach(mirApp => {
      if (groups.includes(mirApp.group || '')) {
        newApps.push(mirApp)
      }
    });
    setAuthorizedApps(newApps)
  }, [getGroups]);

  const redirectToApp = (url: string) => {
    const a = document.createElement('a');
    a.href = url
    a.click();
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 70px)' }}>

      <Grid container
        style={{ width: '100%', marginTop: '20px' }}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography variant='h4'>You're logged in!</Typography>
        </Grid>

        <Grid item xs={12} />

        <Grid item>
          <Typography variant='h5'>You can now choose where to go</Typography>
        </Grid>
      </Grid>

      <Grid container
        style={{ width: '100%' }}
        justifyContent="center"
        alignItems="center"
        sx={{
          flex: 0.75
        }}
      >
        {authorizedApps.length > 0
          ? authorizedApps.map(app => {
            return (
              <Grid item
                xs={2}
                justifyContent="center"
                alignItems="center"
                key={app.group}
              >
                <AppButton title={app.title} image={!!app.logo ? app.logo : default_logo} onClick={() => redirectToApp(app.url)} />
              </Grid>
            )
          })
          : <Grid item>
            <Typography variant='h5'>You have no apps!</Typography>
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default AppSelector;