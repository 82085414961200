import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useOktaAuth } from '@okta/okta-react';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

import {  Box, CircularProgress, Container, Divider, FormControl, Grid, IconButton, InputAdornment, InputLabel, SxProps, TextField, Typography, useTheme } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import logo from './../../logo.jpeg';
import './Login.css'
import mirSentinelTheme from '../../mir-styles/src/styles/general';
import { LoadingButton } from '@mui/lab';
import { AlertMsgContext, AlertMsgType } from '../../mir-styles/src/providers/AlertMsgProvider';
import { AuthContext, AuthType } from '../../mir-styles/src/providers/AuthProvider';


const textSxOverrides: SxProps = {
  paddingLeft: '42px',
  paddingRight: '42px',
  marginBottom: '10px',
  'label + &': {
    marginTop: mirSentinelTheme.spacing(6),
  },
  '& .MuiOutlinedInput-root': {
    borderColor: mirSentinelTheme.palette.primary.main,
    borderWidth: '1px',
    borderRadius: '1rem',
    '& fieldset': {
      borderColor: mirSentinelTheme.palette.primary.main,
    },
    ':not(.Mui-error)': {
      '&.Mui-focused fieldset': {
        boxShadow: `0 0 20px ${mirSentinelTheme.palette.primary.main}`,
      }
    },
    '&.Mui-error.Mui-focused fieldset': {
      boxShadow: `0 0 10px ${mirSentinelTheme.palette.error.main}`
    }
  }
}


const Login = ({ setCorsErrorModalOpen }: any) => {
  const theme = useTheme();
  const { updateAlertMsg } = useContext(AlertMsgContext) as AlertMsgType;
  const { checkToken } = useContext(AuthContext) as AuthType;

  const [username, setUsername] = useState<string>('')
  const [usernameError, setUsernameError] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string>('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies(['mir_auth']);

  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (checkToken()) {
      const source = searchParams.get("source")
      if (!!source) {
        const a = document.createElement('a');
        a.href = source
        a.click();
      } else {
        navigate('/apps', { replace: true })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  function handleTransaction(transaction: any) {
    setLoading(false)

    if (transaction.messages) {
      transaction.messages.forEach((message: any) => {
        if (message.class === 'ERROR') {
          updateAlertMsg({
            severity: 'error',
            message: message.message,
            open: true
          })
        } else {
          updateAlertMsg({
            severity: 'info',
            message: message.message,
            open: true
          })
        }
      })
      console.log('messages: ', transaction.messages);
    }
    console.log('status: ', transaction.status)

    switch (transaction.status) {
      case 'PENDING':
        console.log('error_p: ', transaction.error);
        break;
      case 'FAILURE':
        updateAlertMsg({
          severity: 'error',
          message: "Unable to sign in",
          open: true
        })
        console.log('error: ', transaction.error);
        break;
      case 'SUCCESS':
        updateAlertMsg({
          severity: 'success',
          message: "You've logged in successfully",
          open: true
        })
        oktaAuth.tokenManager.setTokens(transaction.tokens)
        setCookie('mir_auth', transaction.tokens, { domain: process.env.REACT_APP_COOKIES_DOMAIN });
        break;
      default:
        throw new Error(transaction.status + ' status');
    }
  }

  const _handleLogin = () => {
    setLoading(true)
    oktaAuth.idx.authenticate({ username, password })
      .then(res => handleTransaction(res))
      .catch(err => console.log(err));
  }

  const _handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const _handlePasswordInput = (newPassword: string) => {
    setPassword(newPassword)
    if (!newPassword) setPasswordError("Password is required")
    else setPasswordError(undefined)
  }

  const _handleUsernameInput = (newUsername: string) => {
    setUsername(newUsername)
    if (!newUsername) setUsernameError("Username is required")
    else setUsernameError(undefined)
  }


  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: 'calc(80vh)'
    }}>
      <Container
        sx={{
          borderColor: theme.palette.primary.main,
          borderWidth: '2px',
          borderRadius: '2rem',
          borderStyle: 'solid',
          maxWidth: '500px',
          width: '500px',
          padding: '0 !important',
        }}
      >
        {/* <img src={logo} sx={{height: '100px'}}/> */}

        <Box component="img" src={logo} sx={{ height: '100px' }} />

        <Divider sx={{
          backgroundColor: theme.palette.primary.main,
        }} />

        <Grid container>

          <Grid item xs={12}>
            <Typography variant='h5'
              sx={{
                marginTop: '30px',
                fontWeight: 500
              }}
            >
              Sign in to miR's apps
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="username-input" sx={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center'
              }}>
                Username
              </InputLabel>
              <TextField
                id="username-input"
                fullWidth
                sx={textSxOverrides}
                value={username}
                onChange={e => _handleUsernameInput(e.target.value)}
                error={!!usernameError}
                helperText={usernameError}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel htmlFor="password-input" sx={{
                fontWeight: 'bold',
                width: '100%',
                textAlign: 'center'
              }}>
                Password
              </InputLabel>
              <TextField
                id="password-input"
                fullWidth
                sx={textSxOverrides}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={e => _handlePasswordInput(e.target.value)}
                error={!!passwordError}
                helperText={passwordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={_handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}
            sx={{
              paddingLeft: '42px',
              paddingRight: '42px'
            }}>
            <LoadingButton
              loading={loading}
              loadingIndicator={
                <>
                  SIGNING IN... &nbsp;
                  <CircularProgress color="inherit" size={18} />
                </>
              }
              onClick={_handleLogin}
              variant='contained'
              disabled={!!usernameError || !!passwordError || username === '' || password === ''}
              sx={{
                marginTop: '20px',
                marginBottom: '30px',
                height: '60px',
                width: '100%',
                backgroundColor: mirSentinelTheme.palette.primary.main,
                borderRadius: '2rem',
                '&:hover': {
                  backgroundColor: mirSentinelTheme.palette.primary.light,
                  boxShadow: 'none',
                },
              }}
            >
              SIGN IN
            </LoadingButton>
          </Grid>

        </Grid>

      </Container>
      {/* <div ref={widgetRef} /> */}

    </Box>
  );
};

export default Login;
